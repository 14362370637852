<style lang="scss"></style>
<!--eslint-disable-->
<template>
  <div>
    <b-card class="col-md-6 mx-auto mt-5">
      <section class="text-center">
        <h1 class="text-center mb-2">Thank you for your feedback</h1>
        <h3 class="text-center font-weight-normal mb-2">Your issue number is <b>#{{uid}}</b></h3>

        <p>We appreciate the time you have taken to submit a report and take all submissions into account.
          We hope to resolve your issue soon and aim to make the MDM a great experience for everyone.</p>
      </section>
    </b-card>

    <b-card no-body class="mb-0">

    </b-card>
  </div>
</template>
<!--eslint-enable-->

<script>
  export default {
    name: 'SubmittedIssue',
    components: {},
    props: {
      uid: {
        type: String,
        required: true,
      },
    },
    data() {
      return {};
    },
    mounted() {},
    methods: {

    },
  };
</script>
